import Vue from "vue"
// import VueCompositionApi from "vue"
import VeeValidate from "@/plugins/vee-validate"
import VueSelect from "@/plugins/vue-select"
import VueEllipseProgress from "@/plugins/vue-ellipse-progress"
import VueDatetime from "@/plugins/vue-datetime"
import { BootstrapVue } from "bootstrap-vue"
import ActionCableVue from "actioncable-vue"
import router from "@/router"
import store from "@/store"
import App from "@/App.vue"
import VueKeycloak from "@/plugins/keycloak";
import FontAwesome from '@/plugins/fontawesome'
import FontAwesomeButton from "@/components/shared/FAButton.vue"
import TitleActions from "@/components/shared/TitleActions.vue"
import LoadingOverlay from "@/components/shared/LoadingOverlay.vue"
import ETable from "@/components/shared/table/ETable.vue"

// Vue.use(VueCompositionApi)

VeeValidate()
FontAwesome()
VueSelect()
VueEllipseProgress()
VueDatetime()

Vue.component('FAButton', FontAwesomeButton)
Vue.component('TitleActions', TitleActions)
Vue.component('ETable', ETable)
Vue.component('LoadingOverlay', LoadingOverlay)


import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 450,
    md: 1250,
    lg: Infinity,
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})

Vue.use(BootstrapVue)

Vue.config.productionTip = false

//import "@/assets/scss/theme-dark.scss"
import "@/assets/scss/theme.scss"
import { KeycloakInstance } from "keycloak-js";

Vue.use(VueKeycloak, {
  config: {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: 'hta',
    clientId: 'pflegehilfe-senioren-platform',
  },
  init: {
    onLoad: 'login-required',
    pkceMethod: 'S256',
    checkLoginIframe: false
  },
  onReady: (keycloak: KeycloakInstance) => {

    Vue.use(ActionCableVue, {
      debug: true,
      debugLevel: 'error',
      connectionUrl: `ws://localhost:8081/cable?authorizationToken=${keycloak.token}`, // or function which returns a string with your JWT appended to your server URL as a query parameter
      connectImmediately: true
    })

    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app")
  }
})

