import { provide, inject, ref, Ref, InjectionKey } from "vue"

interface AlertDialog {
  show: Ref<boolean>,
  message: Ref<string>,
  variant: Ref<string>
}

export let alertDialog: AlertDialog

const alertDialogProvider: () => AlertDialog = () => {
  const show = ref(false)
  const message = ref('')
  const variant = ref('success')

  return {
    show,
    message,
    variant
  }
}

const alertKey: InjectionKey<AlertDialog> = Symbol('alert')

export function provideAlertDialog() {
  alertDialog = alertDialogProvider()
  const instance = alertDialog
  provide(alertKey, instance)
  return instance
}

export function getAlertDialog() {
  const injection = inject<AlertDialog>(alertKey)
  if (!injection) throw Error("No alert dialog instance provided")
  return injection
}


