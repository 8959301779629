import apiClient from './apiClient'
import { Reservation } from "@/models/reservations"
import { CollectionResourceDoc, SingleResourceDoc } from '@/models/jsonapi'

const resource = "/reservations"

export default {
  async create_v2(id?: string, reservedUntil?: string) {
    const baseDomain = process.env.VUE_APP_API
    if (baseDomain) {
      apiClient.defaults.baseURL = baseDomain.replace('v1', 'v2')
      const response = apiClient.post(resource, { attributes: { reservedUntil }, meta: { postingId: id } })
      apiClient.defaults.baseURL = baseDomain.replace('v2', 'v1')
      return response
    }
  }
}
