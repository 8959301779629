
import { defineComponent, onMounted, ref, getCurrentInstance } from 'vue'
import { useModelWrapper } from '@/utils/modelWrapper'
import { Query, Rule } from './querybuilder'


interface Props {
  query: Query,
  rules: Rule[],
  depth: number,
  maxDepth?: number
}

export default defineComponent({
  props: {
    query: {
      type: Object,
      required: true
    },
    ruleTypes: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Array
    },
    maxDepth: {
      type: Number
    },
    depth: {
      type: Number
    }
  },
  setup(props: Props, { emit }) {
    const root = getCurrentInstance().proxy
    let groupComponent: any
    let ruleComponent: any 
    
    const mounted = ref(false)

    const internalQuery = useModelWrapper(props, emit, 'query')

    const removeChild = (index: number) => {
      internalQuery.value.children.splice(index, 1)
      emit('rule-update')
    }
    
    const onRuleUpdate = () => emit('rule-update')

    const getComponent = (type: string) => {
      return type === 'query-builder-group' ? groupComponent : ruleComponent
    }

    onMounted(() => {
      groupComponent = () => import ('./QueryBuilderGroup.vue')
      ruleComponent = () => import ('./QueryBuilderRule.vue')
      mounted.value = true
    })

    return {
      removeChild,
      onRuleUpdate,
      getComponent,
      mounted
    }
  },
})
