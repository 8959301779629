import { provide, inject, ref, Ref, InjectionKey } from "vue"
import Keycloak, { KeycloakInstance } from "keycloak-js";

let installed = false

export const keycloakSymbol: InjectionKey<Ref<KeycloakInstance>> = Symbol('keycloak')

export let keycloak: KeycloakInstance

export default {
  install: function (Vue: any, params = {}) {
    if (installed) return
    installed = true

    const defaultParams = {
      config: {},
      init: { onLoad: 'login-required' }
    }

    const options = Object.assign({}, defaultParams, params)
    if (assertOptions(options).hasError) throw new Error(`Invalid options given: ${assertOptions(options).error}`)

    getConfig(options.config)
      .then(config => {
        init(config, options)
      })
      .catch(err => {
        console.log(err)
      })
  }
}

function init (config: any, options: any) {
  const ctor = sanitizeConfig(config)
  keycloak = Keycloak(ctor)

  keycloak.onReady = function (authenticated) {
    if (typeof options.onReady === 'function') {
      // console.log({authenticated})
      options.onReady(keycloak)
    }
  }

  keycloak.onAuthSuccess = function () {}

  keycloak.onAuthError = function (error) {}

  keycloak.onAuthRefreshSuccess = function () {}

  keycloak.onAuthRefreshError = function () {}

  keycloak.onAuthLogout = function () {}

  keycloak.onTokenExpired = function () {
    keycloak.updateToken(30).catch(() => {
      keycloak.clearToken()
    })
  }

  keycloak.onActionUpdate = function(status: 'success'|'cancelled'|'error') {}

  keycloak.init(options.init)
    .catch(err => {
      typeof options.onInitError === 'function' && options.onInitError(err)
    })
}

function assertOptions (options: any) {
  const {config, init, onReady, onInitError} = options
  if (typeof config !== 'string' && !_isObject(config)) {
    return {hasError: true, error: `'config' option must be a string or an object. Found: '${config}'`}
  }
  if (!_isObject(init) || typeof init.onLoad !== 'string') {
    return {hasError: true, error: `'init' option must be an object with an 'onLoad' property. Found: '${init}'`}
  }
  if (onReady && typeof onReady !== 'function') {
    return {hasError: true, error: `'onReady' option must be a function. Found: '${onReady}'`}
  }
  if (onInitError && typeof onInitError !== 'function') {
    return {hasError: true, error: `'onInitError' option must be a function. Found: '${onInitError}'`}
  }
  return {
    hasError: false,
    error: null
  }
}

function _isObject (obj: any) {
  return obj !== null && typeof obj === 'object' && Object.prototype.toString.call(obj) !== '[object Array]'
}

function getConfig (config: any) {
  if (_isObject(config)) return Promise.resolve(config)
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', config)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText))
        } else {
          reject(Error(xhr.statusText))
        }
      }
    }
    xhr.send()
  })
}

function sanitizeConfig(config: any) {
  const renameProp = (oldProp: any, newProp: any, {[oldProp]: old, ...others}) => {
    return {
      [newProp]: old,
      ...others
    }
  }
  return Object.keys(config).reduce(function (previous, key) {
    if (['authRealm', 'authUrl', 'authClientId'].includes(key)) {
      const cleaned = key.replace('auth', '')
      const newKey = cleaned.charAt(0).toLowerCase() + cleaned.slice(1)
      return renameProp(key, newKey, previous)
    }
    return previous
  }, config)
}

export function provideKeycloak() {
  const instance = ref(keycloak)
  provide(keycloakSymbol, instance)
  return instance
}

export function getKeycloak() {
  const injection = inject<Ref<KeycloakInstance>>(keycloakSymbol)
  if (!injection) throw new Error("No keycloak instance provided")
  return injection
}
