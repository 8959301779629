import { Query, RuleQuery } from '@/components/shared/table/querybuilder/querybuilder'

export function filtersToQuery(filters: { [key: string]: any }) {
  return {
    logicalOperator: 'AND',
    children: Object.entries(filters).map(([key, value]) => ({ type: 'query-builder-rule', query: { rule: key, value: value } }))
  } as Query
}

export function queryToFilters(query: Query) {
  let filters = {} as { [key:string]: any }
  query.children.forEach((child: RuleQuery) => {
    if (child.query.rule) {
      filters[`filter[${child.query.rule}]`]=child.query.value
    }
  })
  return filters
}
