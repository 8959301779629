import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { keycloak } from "@/plugins/keycloak";
import { alertDialog } from "@/plugins/alert"

const baseDomain = process.env.VUE_APP_API
const baseURL = `${baseDomain}`

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    xsrfCookieName: "CSRF-TOKEN",
    xsrfHeaderName: "X-CSRF-Token"
  },
})

const requestHandler = async (request: AxiosRequestConfig) => {
  try {
    // Check if the token needs to be refreshed 30 seconds before expiration
    await keycloak.updateToken(30)
    request.headers['Authorization'] = `Bearer ${keycloak.token}`
    request['meta'] = { ...request?.['meta'], origin: 'pflelegehilfe-platform-old' }
    return request
  } catch (error) {
    keycloak.clearToken()
    throw new Error(error)
  }
}

const responseSuccessHandler = (response: AxiosResponse) => {
  if (response.config.method === 'post' || response.config.method === 'patch') {
    if (response.status === 200 || response.status === 201) {
      // Show success message
      alertDialog.variant.value = 'success'
    } else {
      // Show error message
      alertDialog.variant.value = 'error'
    }
    alertDialog.message.value = JSON.stringify(response.data.message, null, 2)
    alertDialog.show.value = true
  }
  return response
}

axiosInstance.interceptors.request.use((request) => requestHandler(request))

axiosInstance.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => {
    alertDialog.variant.value = 'danger'
    // alertDialog.message.value = error.response.data.errors[0]
    alertDialog.message.value = JSON.stringify(error.response.data.errors, null, 2)
    alertDialog.show.value = true
    return Promise.reject(error.response)
  })

export default axiosInstance
