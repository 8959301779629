import apiClient from './apiClient'
import { CollectionResourceDoc, SingleResourceDoc } from '@/models/jsonapi'
import { Lead } from "@/models/leads"

const resource = "/leads"

export default {
  async list(queryParams?: { [key: string]: any }) {
    const response = await apiClient.get<CollectionResourceDoc<string, Lead>>(resource, { params: queryParams })
    return response.data
  },
  listV2(queryParams?: { [key: string]: any }) {
    const baseDomain = process.env.VUE_APP_API
    if (baseDomain) {
      apiClient.defaults.baseURL = baseDomain.replace('v1', 'v2')
      const response = apiClient.get<CollectionResourceDoc<string, Lead>>(resource, { params: queryParams })
      apiClient.defaults.baseURL = baseDomain.replace('v2', 'v1')
      return response
    }
  },
  async get(id: string, queryParams?: { [key: string]: any }) {
    let params = {
      'filter[id]': id
    }
    if (queryParams) {
      params = {
        ...params,
        ...queryParams
      }
    }
    const response = await apiClient.get<CollectionResourceDoc<string, Lead>>(resource, { params: params })
    return response.data
  },
  async create(data?: any) {
    const response = await apiClient.post(resource, data)
    return response.data
  },
  async edit(id: string, data?: any) {
    const response = await apiClient.patch(`${resource}/${id}`, data)
    return response.data
  },
  async delete(id: string) {
    const response = await apiClient.delete(`${resource}/${id}`)
    return response.data
  },
  async deleteLog(id: string, logId: string) {
    const response = await apiClient.delete(`${resource}/${id}/log_destroy/${logId}`)
    return response.data
  }
}
