import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('care_giver_instances')

export const SEARCH = 'search'
export const SET_SEARCH_RESULT = 'set_search_result'

const extendedState = {
  ...state,
  searchResult: undefined
}

const extendedActions = {
  ...actions,
  async [SEARCH]({ commit }: any, queryParams?: { [key:string]: any }) {
    const responseData = await Repository.search(queryParams)
    commit(SET_SEARCH_RESULT, responseData)
    return responseData
  }
}

const extendedMutations = {
  ...mutations,
  [SET_SEARCH_RESULT](state: any, searchResult: any) {
    state.searchResult = searchResult
  }
}

export default {
  namespaced,
  state: extendedState,
  getters,
  actions: extendedActions,
  mutations: extendedMutations,
}
