import { render, staticRenderFns } from "./HeadCell.vue?vue&type=template&id=2ec24960&scoped=true&lang=pug"
import script from "./HeadCell.vue?vue&type=script&lang=ts"
export * from "./HeadCell.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ec24960",
  null
  
)

export default component.exports