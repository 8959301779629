
  import { computed, defineComponent, ref } from "vue"

  interface Props {
    buttons?: {
      prev: string,
      next: string,
      last?: string
    },
    initialData: object,
    showHeader: boolean,
    alertEnabled: boolean,
    form: object
  }

  export default defineComponent({
    name: "FormStepper",
    props: {
      showHeader: {
        type: Boolean,
        default: true
      },
      alertEnabled: {
        type: Boolean,
        default: true
      },
      buttons: {
        type: Object
      },
      form: {
        type: Object,
        default: () => ({})
      }
    },
    setup(props: Props, { slots, emit }) {
      const observer = ref<{ validate: () => boolean, [key:string]: any}>()
      const reservedSlots = {
        BeforeStepsHeader: "before_steps_header",
        StepsHeader: "steps_header",
        AfterStepsHeader: "after_steps_header"
      }

      const steps = computed(() => {
        return Object.keys(slots)
          .filter(key => !Object.values(reservedSlots).includes(key))
      })
      const currentStep = ref(0)
      const totalSteps = computed(() => steps.value.length)
      const errorMessage = ref<string>()

      const isLastStep = computed(() => currentStep.value === totalSteps.value - 1)
      const nextButtonText = computed(() => {
        if (props.buttons?.last && isLastStep.value) {
          return props.buttons.last
        } else {
          return props.buttons?.next
        }
      })

      const previousStep = () => currentStep.value--

      const nextStep = async () => {
        // Validate input if the observer is present
        if (observer.value) {
          const valid = await observer.value.validate()
          if (!valid) {
            errorMessage.value = "Dieser Step ist nicht korrekt."
            return
          }
          errorMessage.value = undefined
        }

        // Check if we are at the last step
        if (!isLastStep.value) {
          currentStep.value++
          return
        }

        // Last step, send the form
        emit('done', props.form)
      }

      const updateCurrentStep = (index: number) => currentStep.value = index

      return {
        observer,
        reservedSlots,
        steps,
        totalSteps,
        currentStep,
        errorMessage,
        nextButtonText,
        updateCurrentStep,
        previousStep,
        nextStep
      }
    }
  })
