import apiClient from './apiClient'
import { Message } from "@/models/messages"

const resource = "/messages"

export default {
  async list() {
    const response = await apiClient.get<Message[]>(resource)
    return response.data
  },
  async get(id: string, queryParams?: { [key:string]: any  }) {
    const response = await apiClient.get<Message>(`${resource}/${id}`)
    return response.data
  },
  async create(data?: any) {
    const response = await apiClient.post(resource, data)
    return response.data
  },
  async edit(id: string, data?: any) {
    const response = await apiClient.patch(`${resource}/${id}`, data)
    return response.data
  },
  async delete(id: string) {
    const response = await apiClient.delete(`${resource}/${id}`)
    return response.data
  }
}
