import apiClient from './apiClient'
import { CollectionResourceDoc, SingleResourceDoc } from '@/models/jsonapi'
import { List } from "@/models/lists"

const resource = "/lists"

export default {
  async list(queryParams: { [key:string]: any  }) {
    const response = await apiClient.get<CollectionResourceDoc<string, List>>(resource, { params: queryParams })
    return response.data
  }
}
