import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from "vee-validate"
import { required, email, regex } from 'vee-validate/dist/rules'

export default function() {
  extend('required', {
    ...required,
    message: "{_field_} muss ausgefüllt werden" // the error message
  })

  extend('email', {
    ...email,
    message: "{_field_} ist nicht ein richtiger {_rule_}"
  })

  extend('regex', {
    ...regex,
    message: "{_field_} ist nicht richtig. {_value_} geht nich für die gewählte {_rule_} Regel mit Pattern {regex}"
  })

  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)
}