import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('reservations')

export const CREATEV2 = 'create_v2'

const extendedActions = {
  ...actions,
  async [CREATEV2]({ commit }: any, payload: { id: string, reservedUntil?: string }) {
    return await Repository.create_v2(payload.id, payload?.reservedUntil)
  }
}

export default {
  namespaced,
  state,
  getters,
  actions: extendedActions,
  mutations,
}