
  import { defineComponent, ref } from 'vue'

  export default defineComponent({
    name: "HeadCell",
    props: {
      label: {
        type: String,
        required: true
      },
      tooltip: {
        type: String
      },
    },
    setup() {
      const title = ref('')
      return {
        title
      }
    }
  })
