import ModuleFactory from '@/store/modules/moduleFactory'
import { apiDataMorpher } from "@/utils/apiDataHelper"


const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('agencies')

export const GET_PROFILE = 'get_profile'
export const SET_PROFILE = 'set_profile'

const extendedState = {
  ...state,
  profile: undefined
}

const extendedActions = {
  ...actions,
  async [GET_PROFILE]({ commit }: any) {
    const responseData = await Repository.profile()
    apiDataMorpher(responseData, 'company_address')
    commit(SET_PROFILE, responseData.data[0])
  }
}
  
const extendedMutations = {
  ...mutations,
  [SET_PROFILE](state: any, profile: any) {
    state.profile = profile
  }
}

export default {
  namespaced,
  state: extendedState,
  getters,
  actions: extendedActions,
  mutations: extendedMutations,
}
