import { ref, provide, inject, Ref } from "vue"
import jsonpointer from "jsonpointer"

const formSymbol = Symbol()

const state = ref<any>({})

export function setElement(dataPath: string, value: any) {
  let stateValueCopy = { ...state.value } 
  jsonpointer.set(stateValueCopy, dataPath, value)
  state.value = stateValueCopy
}

export function provideForm(initialValue?: any) {
  if (initialValue) {
    state.value = initialValue
  }
  provide(formSymbol, state)
  return state
}

export function getForm() {
  const injection = inject<Ref<any>>(formSymbol)
  if (!injection) throw new Error("No form instance provided")
  return injection
}
