
  import { computed, defineComponent } from "vue"

  interface Props {
    icon: string,
    border: boolean,
    color?: string,
    faClasses?: string
  }

  export default defineComponent({
    name: 'FAButton',
    props: {
      icon: {
        type: String,
        required: true,
      },
      border: {
        type: Boolean,
        default: true,
      },
      color: String,
      faClasses: String,
    },
    setup(props: Props) {
      const cssObject = computed(() => {
        return { '--color': props.color }
      })

      const classObject = () => {
        return {
          'ghost-border': !props.border,
          'default-color': !props.color,
          'custom-color': !!props.color,
        }
      }
      return {
        cssObject,
        classObject
      }
    }
  })
