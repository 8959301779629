import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('users')

export const SET_CURRENT_USER = 'set_current_user'
export const GET_CURRENT_USER = 'get_current_user'
export const GET_SELLER = 'get_seller'
export const UPDATE_USER_SETTINGS = 'update_user_settings'
export const SET_CURRENT_USER_SETTINGS = 'set_current_user_settings'

const extendedState = {
  ...state,
  currentUser: undefined
} as { currentUser: any, [key:string]: any }

const extendedActions = {
  ...actions,
  async [GET_CURRENT_USER]({ commit }: any) {
    const responseData = await Repository.getCurrentUser()
    commit(SET_CURRENT_USER, responseData.data)
  },
  async [UPDATE_USER_SETTINGS]({ commit }: any, payload: { id: string, settings: any }) {
    if (payload.id === extendedState.currentUser?.data?.id) {
      commit(SET_CURRENT_USER_SETTINGS, payload.settings)
    }
    Repository.updateUserSettings(payload.id, payload.settings)
  },
  async [GET_SELLER]({ commit }: any, payload: any) {
    const resourceIdentifier = {
      id: payload.id,
      type: payload.type
    }
    const response = await Repository.getSeller(resourceIdentifier)
    return response.data
  },
}

const extendedMutations = {
  ...mutations,
  [SET_CURRENT_USER](state: any, currentUser: any) {
    state.currentUser = currentUser
  },
  [SET_CURRENT_USER_SETTINGS](state: any, settings: any) {
    const currentUser = { ...state.currentUser }
    if (currentUser) {
      currentUser.data.attributes.settings = settings
    }
    state.currentUser = currentUser
  },
}

export default {
  namespaced,
  state: extendedState,
  getters,
  actions: extendedActions,
  mutations: extendedMutations,
}
