import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('care_stays')

export const GET_PRESENTATION_MAIL_DATA = 'get_presentation_mail_data'
export const SET_PRESENTATION_MAIL_DATA = 'set_presentation_mail_data'
// export const CS_ABORT = 'cs_abort'
// export const CS_END = 'cs_end'

const extendedActions = {
  ...actions,
  async [GET_PRESENTATION_MAIL_DATA]({ commit }: any, suggestionIds: string[]) {
    const responseData = await Repository.presentationMailData(suggestionIds)
    commit(SET_PRESENTATION_MAIL_DATA, responseData.data)
  }
  // async [CS_ABORT]({ commit }: any, data: {month: number, year: number}) {
  //   return Repository.edit(data)
  //   // commit(SET_NEW_POSTING_DATA, response.data)
  // },
}

const extendedMutations = {
  ...mutations,
  [SET_PRESENTATION_MAIL_DATA](state: any, presentationMailData: any) {
    state.presentationMailData = presentationMailData
  }
}

export default {
  namespaced,
  state,
  getters,
  actions: extendedActions,
  mutations: extendedMutations,
}
