import { provide, inject, ref, Ref, InjectionKey } from "vue"

interface ConfirmationDialog {
  visible: Ref<boolean>,
  text: Ref<string>,
  confirmation: Function,
  done: Function
}

export const confirmKey: InjectionKey<ConfirmationDialog> = Symbol('confirm')

export function provideConfirmationModal() {
  const modal = confirmationModal()
  const instance = modal
  provide(confirmKey, instance)
  return instance
}

export function getConfirmationModal() {
  const injection = inject<ConfirmationDialog>(confirmKey)
  if (!injection) throw Error("No confirmation modal instance provided")
  return injection
}

const confirmationModal: () => ConfirmationDialog = () => {
  const visible = ref(false)
  const text = ref('')

  let next: Function = () => {}

  const confirmation = (inputText: string, onDone?: Function) => {
    visible.value = true
    text.value = inputText
    if (onDone) {
      next = onDone
    } else {
      next = () => {}
    }
  }

  const done = (value: boolean) => {
    visible.value = false
    next()
  }

  return {
    visible,
    text,
    confirmation,
    done
  }
}
