import AddressRepository from '@/api/addressesRepository'
import AgencyRepository from '@/api/agenciesRepository'
import AgencyBillRepository from '@/api/agencyBillsRepository'
import BillAddressRepository from '@/api/billaddressesRepository'
import CareGiverRepository from '@/api/careGiversRepository'
import CareReceiverRepository from '@/api/careReceiversRepository'
import CareStayRepository from '@/api/careStaysRepository'
import CareGiverInstanceRepository from '@/api/careGiverInstancesRepository'
import ContractRepository from '@/api/contractsRepository'
import DashboardRepository from '@/api/dashboardRepository'
import FileMetaRepository from '@/api/fileMetaDataRepository'
import HouseholdRepository from '@/api/householdsRepository'
import LeadRepository from '@/api/leadsRepository'
import LeadsourceRepository from '@/api/leadsourcesRepository'
import ProtoLeadsourceRepository from '@/api/protoLeadsourcesRepository'
import ListRepository from '@/api/listsRepository'
import LogRepository from '@/api/logsRepository'
import MessageRepository from '@/api/messagesRepository'
import PostingRepository from '@/api/postingsRepository'
import SellerBillRepository from '@/api/sellerBillsRepository'
import SegmentRepository from '@/api/segmentsRepository'
import SubscriptionRepository from '@/api/subscriptionsRepository'
// import SuggestionRepository from '@/api/suggestionsRepository'
import TaskRepository from '@/api/tasksRepository'
import TicketRepository from '@/api/ticketsRepository'
import UserRepository from '@/api/usersRepository'
import VisorRepository from '@/api/visorsRepository'
import FileMetaDataRepository from '@/api/fileMetaDataRepository'
import ReservationRepository from '@/api/reservationsRepository'

const repositories = {
  addresses: AddressRepository,
  agencies: AgencyRepository,
  agency_bills: AgencyBillRepository,
  bill_addresses: BillAddressRepository,
  care_givers: CareGiverRepository,
  care_giver_instances: CareGiverInstanceRepository,
  care_receivers: CareReceiverRepository,
  care_stays: CareStayRepository,
  contracts: ContractRepository,
  dashboard: DashboardRepository,
  file_metas: FileMetaRepository,
  file_meta_datas: FileMetaDataRepository,
  households: HouseholdRepository,
  leads: LeadRepository,
  leadsources: LeadsourceRepository,
  lists: ListRepository,
  logs: LogRepository,
  messages: MessageRepository,
  postings: PostingRepository,
  proto_leadsources: ProtoLeadsourceRepository,
  segments: SegmentRepository,
  seller_bills: SellerBillRepository,
  subscriptions: SubscriptionRepository,
  // suggestions: SuggestionRepository,
  tasks: TaskRepository,
  tickets: TicketRepository,
  users: UserRepository,
  visors: VisorRepository,
  reservations: ReservationRepository,
}

export default {
  get: (name: string) => repositories[name]
}
