import apiClient from './apiClient'
import {AxiosRequestConfig} from 'axios'
import { FileMetaData } from "@/models/file_meta_datas"
import { CollectionResourceDoc, SingleResourceDoc, ResourceObject } from '@/models/jsonapi'

const resource = "/file_meta_datas"

export default {
  async list(queryParams?: { [key:string]: any  }) {
    const response = await apiClient.get<CollectionResourceDoc<string, FileMetaData>>(resource, { params: queryParams })
    return response.data
  },
  async get(id: string, queryParams?: { [key:string]: any  }) {
    let params = {
      'filter[id]': id
    }
    if (queryParams) {
      params = {
        ...params,
        ...queryParams
      }
    }
    const response = await apiClient.get<CollectionResourceDoc<string, FileMetaData>>(resource, { params: params })
    return response.data
  },
  async create(data?: any) {
    const response = await apiClient.post(resource, data)
    return response.data
  },
  async edit(id: string, data?: any) {
    const response = await apiClient.patch(`${resource}/${id}`, data)
    return response.data
  },
  async delete(id: string) {
    const response = await apiClient.delete(`${resource}/${id}`)
    return response.data
  },
  async displayFile(fmd: ResourceObject<string, FileMetaData>, shouldDownload: boolean = false) {
    const fmdUrl = `${process.env.VUE_APP_API}/file_meta_datas/${fmd.id}`
    const requestOptions: AxiosRequestConfig = { responseType: 'arraybuffer' }
    apiClient.get(fmdUrl, requestOptions)
      .then( (r) => {
        const blob = new Blob([r.data], {type: fmd.attributes?.contentType})
        const pdfUrl = window.URL.createObjectURL(blob);
          var linkEl = document.createElement('a');
          linkEl.href = pdfUrl;
          linkEl.target = '_blank';
          if (shouldDownload && fmd.attributes?.filename) {
            linkEl.download = fmd.attributes?.filename;
          }
          linkEl.click();
          setTimeout(function(){
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(pdfUrl);
          }, 100);
      })
  },
}
