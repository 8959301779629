
import { computed, defineComponent, ref, provide, InjectionKey, watch } from "vue"
import { getKeycloak } from "@/plugins/keycloak";

export default defineComponent({
  name: "user-hub",
  props: {
    viewName: {
      type: String,
      required: true
    },
    id: {
      type: String
    }
  },
  setup(props) {
    const sharedViews = ['CareReceiver', 'CareGiverInstance']
    const keycloakRef = getKeycloak()
    const role = computed(() => keycloakRef.value.tokenParsed?.resource_access?.['pflegehilfe-senioren-platform']['roles'][0])
    const notSellerRole = computed(() => role.value !== 'seller')

    const userComponent = computed(() => {
      if (sharedViews.filter(name => name === props.viewName).length > 0) {
        return () => import(`@/views/shared/${props.viewName}.vue`)
      }
      return () => import(`@/views/${role.value}/${props.viewName}.vue`)
    })

    const resourceId = computed(() => {
      return props.id === 'router-view' ? undefined : props.id
    })

    watch(() => role, (userRole) => {
        if (userRole.value === 'seller') {
          window.location.replace(process.env.APP_VUE_NEW)
        } 
      }, { immediate: true })

    return {
      userComponent,
      resourceId,
      notSellerRole
    }
  }
})
