import apiClient from './apiClient'
import { Tile } from "@/models/dashboard"

const resource = "/dashboard"

export default {
  // list() {
  //   return apiClient.get<Tile[]>(resource)
  // }
  async list() {
    const response = await apiClient.get(resource)
    return response.data
  }
}
