import apiClient from './apiClient'
import { CollectionResourceDoc, SingleResourceDoc } from '@/models/jsonapi'
import { Leadsource } from "@/models/leadsources"

const resource = "/leadsources"

export default {
  async list(queryParams?: { [key:string]: any  }) {
    const response = await apiClient.get<CollectionResourceDoc<string, Leadsource>>(resource, { params: queryParams })
    return response.data
  },
  async get(id: string, queryParams?: { [key:string]: any  }) {
    let params = {
      'filter[id]': id
    }
    if (queryParams) {
      params = {
        ...params,
        ...queryParams
      }
    }
    const response = await apiClient.get<CollectionResourceDoc<string, Leadsource>>(resource, { params: params })
    return response.data
  },
  async create(data?: any) {
    const response = await apiClient.post(resource, data)
    return response.data
  },
  async edit(id: string, data?: any) {
    const response = await apiClient.patch(`${resource}/${id}`, data)
    return response.data
  },
  async delete(id: string) {
    const response = await apiClient.delete(`${resource}/${id}`)
    return response.data
  },
  async deleteLog(id: string, logId: string) {
    const response = await apiClient.delete(`${resource}/${id}/log_destroy/${logId}`)
    return response.data
  }
}
