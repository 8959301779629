
import { computed, defineComponent, ref, watch, getCurrentInstance } from "vue"
import { getKeycloak } from "@/plugins/keycloak";
import { apiDataMorpher, modelTranslation } from "@/utils/apiDataHelper"

export default defineComponent({
  setup() {
    const keycloakRef = getKeycloak()
    const allRoles = ['admin', 'assistant', 'homepage', 'internal', 'leadsource', 'provider', 'seller']
    const role = computed(() => keycloakRef.value.tokenParsed?.resource_access?.['pflegehilfe-senioren-platform']['roles'][0])
    const logoutHandler = () => keycloakRef.value.logout()

    const visibleFor = (roles: string) => {
      if (role.value) {
        return roles.split(',').indexOf(role.value) > 0
      } else { return false }
    }
    const inVisibleFor = (roles: string) => {
      if (role.value) {
        return roles.split(',').indexOf(role.value) < 0
      } else { return false }
    }

    const root = getCurrentInstance()?.proxy
    root.$store.dispatch('users/get_current_user')
    const currentUser = computed(() => root.$store.state.users.currentUser)
    const avatarUrl = computed(() => currentUser.value?.data?.meta?.avatarUrl ?? '@/assets/img/avatars/profiles/avatar-1.jpg')
    watch(currentUser, (updatedUser) => {
      // Set the theme from the settings if it exists
      const dark = updatedUser?.data.attributes?.settings?.dark
      if (dark === null || dark === undefined) return
      if (dark) {
        localStorage.setItem('theme', 'dark')
        document.documentElement.className = 'dark'
      } else {
        localStorage.setItem('theme', 'light')
        document.documentElement.className = 'light'
      }
    })

    const navItems = [
      {
        title: 'Dashboard',
        to: {
          name: 'dashboard'
        },
        icon: ['fas', 'home']
      },
      {
        title: 'Verfügbare Stellen',
        to: '/v2/postings',
        icon: ['fas', 'newspaper'],
        roles: ['provider']
      },
      {
        title: 'Reservierungen',
        to: '/v2/reservations',
        icon: ['fas', 'calendar-check'],
        roles: ['provider']
      },
      {
        title: 'Leads',
        to: {
          name: 'leads'
        },
        icon: ['fas', 'funnel-dollar'],
        roles: allRoles.filter(role => role !== 'provider')
      },
      {
        title: 'Haushalte',
        to: {
          name: 'households'
        },
        icon: ['fas', 'house-user'],
        roles: allRoles.filter(role => role !== 'provider')
      },
      
      // {
      //   title: 'Vorschläge',
      //   to: {
      //     name: 'suggestions'
      //   },
      //   icon: ['fas', 'hand-holding-heart'],
      //   roles: ['provider']
      // },
      {
        title: 'Stellenausschreibungen',
        to: { name: 'postings' },
        icon: ['fas', 'address-card'],
        roles: ['seller', 'internal']
      },
      {
        title: 'Verträge',
        to: {
          name: 'contracts'
        },
        icon: ['fas', 'file-contract']
      },
      {
        title: 'Agenturen',
        to: {
          name: 'agencies'
        },
        icon: ['fas', 'people-arrows'],
        roles: ['admin', 'internal']
      },
      {
        title: 'Betreuungkräfte',
        to: {
          name: 'care_givers'
        },
        icon: ['fas', 'user-nurse'],
        roles: ['admin', 'internal', 'provider']
      },
      {
        title: 'Vorschläge',
        to: {
          name: 'care_stays'
        },
        icon: ['fas', 'user-nurse'],
        roles: ['internal', 'provider', 'seller']
      },
      {
        title: 'Pflegebedürftige',
        to: {
          name: 'care_receivers'
        },
        icon: ['fas', 'hand-holding-heart'],
        roles: allRoles.filter(role => role !== 'provider')
      },
      {
        title: 'Tickets',
        to: {
          name: 'tickets'
        },
        icon: ['fas', 'ticket-alt'],
        roles: allRoles
      },
      {
        title: 'Users',
        to: {
          name: 'users'
        },
        icon: ['fas', 'users'],
        roles: ['admin', 'internal'],
      },
      {
        title: 'Tasks',
        to: {
          name: 'tasks'
        },
        icon: ['fas', 'tasks'],
        roles: allRoles.filter(role => role !== 'provider')
      },
      // {
      //   title: 'Agentur Rechnungen',
      //   to: {
      //     name: 'agency_bills'
      //   },
      //   icon: ['fas', 'file-invoice-dollar'],
      //   roles: ['admin']
      //   // roles: ['admin', 'provider']
      // },
      {
        title: 'Agentur Abrechnungen',
        to: '/v2/agency_bills',
        // to: {
        //   name: 'agency_bills'
        // },
        icon: ['fas', 'file-invoice-dollar'],
        roles: ['provider', 'admin']
      },
      {
        title: 'Vertriebsabrechnungen',
        to: '/v2/seller_bills',
        // to: {
        //   name: 'seller_bills'
        // },
        icon: ['fas', 'file-invoice-dollar'],
        roles: ['seller', 'admin']
      },
      // {
      //   title: 'KB Rechnungen Alt',
      //   to: {
      //     name: 'seller_bills_old'
      //   },
      //   icon: ['fas', 'file-invoice-dollar'],
      //   roles: ['admin']
      // },
      // {
      //   title: 'Statistics',
      //   to: {
      //     name: 'statistics'
      //   },
      //   icon: ['fas', 'signal'],
      //   roles: ['admin']
      // }
      {
        title: 'Stellensuche',
        to: {
          name: 'care_receiver_search'
        },
        icon: ['fas', 'search'],
        roles: ['admin', 'internal']
      },
      {
        title: 'Stellenausschreibungen',
        to: {
          name: 'visors'
        },
        icon: ['fas', 'funnel-dollar'],
        roles: ['provider']
      },
    ]

    const searchBox = ref()
    const focusSearchBox = () => searchBox.value?.focus()

    const searchColumns = ref([
      {
        component: () => import("@/components/shared/table/cells/LinkCell.vue"),
        key: "attributes.addressableType",
        title: "Typ",
        sortable: true,
        options: {
          contentFunction: (data: any) => {
            return modelTranslation(data.item.attributes.addressableType)
          },
          hrefFunction: (data: any) => `/${data.item.relationships.addressable.data.type}/${data.item.relationships.addressable.data.id}`
        }
      },
      {
        component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        key: "attributes.firstName",
        title: "Vorname",
        sortable: true,
      },
      {
        component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        key: "attributes.lastName",
        title: "Nachname",
        sortable: true,
      }
    ])
    const searchResult = computed(() => root.$store.state.addresses.searchResult?.data)
    const totalItems = computed(() => root.$store.state.addresses.searchResult?.meta?.totalItems ?? 0)

    const searchPhrase = ref('')
    const pageOptions = [10, 25, 50]
    const perPage = ref(pageOptions[0])
    const currentPage = ref(1)

    watch([perPage, currentPage], ([newPerPageValue, newCurrentPageValue]) => {
      searchAddress(newCurrentPageValue, newPerPageValue)
    })

    const searchAddress = async (pageNumber: number = 1, pageSize: number = 10) => {
      const phrase = searchPhrase.value
      if (phrase.length < 3) return
      const queryParams = {
        'page[number]': pageNumber,
        'page[size]': pageSize,
        'include': 'addressable',
      }

      root.$store.dispatch('addresses/search', { phrase: phrase, queryParams: queryParams })
    }

    return {
      navItems,
      role,
      logoutHandler,
      avatarUrl,
      visibleFor,
      inVisibleFor,
      searchResult,
      searchColumns,
      totalItems,
      perPage,
      pageOptions,
      currentPage,
      searchBox,
      focusSearchBox,
      searchAddress,
      searchPhrase
    }
  }
})
