import ModuleFactory from '@/store/modules/moduleFactory'
import { ResourceObject } from '@/models/jsonapi'
import { FileMetaData } from "@/models/file_meta_datas";

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('file_meta_datas')

export const DISPLAY_FILE = 'display_file'

const extendedActions = {
  ...actions,
  async [DISPLAY_FILE]({ commit }: any, payload: { fmd: ResourceObject<string, FileMetaData>, shouldDownload: boolean }) {
    Repository.displayFile(payload.fmd, payload.shouldDownload)
  }
}

export default {
  namespaced,
  state,
  getters,
  actions: extendedActions,
  mutations,
}
