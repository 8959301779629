import apiClient from './apiClient'
import { CollectionResourceDoc, SingleResourceDoc } from '@/models/jsonapi'
import { Posting } from "@/models/postings"

const resource = "/postings"

export default {
  async list(queryParams?: { [key: string]: any }) {
    const response = await apiClient.get<CollectionResourceDoc<string, Posting>>(resource, { params: queryParams })
    return response.data
  },
  listV2(queryParams?: { [key: string]: any }) {
    const baseDomain = process.env.VUE_APP_API
    if (baseDomain) {
      apiClient.defaults.baseURL = baseDomain.replace('v1', 'v2')
      const response = apiClient.get<CollectionResourceDoc<string, Posting>>(resource, { params: queryParams })
      apiClient.defaults.baseURL = baseDomain.replace('v2', 'v1')
      return response
    }
  },
  async get(id: string, queryParams?: { [key: string]: any }) {
    let params = {
      'filter[id]': id
    }
    if (queryParams) {
      params = {
        ...params,
        ...queryParams
      }
    }
    const response = await apiClient.get<CollectionResourceDoc<string, Posting>>(resource, { params: params })
    return response.data
  },
  async edit(id: string, data?: any) {
    const response = await apiClient.patch(`${resource}/${id}`, data)
    return response.data
  },
  async getNewPostingData(id: string) {
    const response = await apiClient.get(`${resource}/${id}/posting_new`)
    return response.data
  },
  // async createNewPosting(id: string, data?: any) {
  //   const response = await apiClient.post(`${resource}/${id}/posting_create`, data)
  //   return response.data
  // },
  async deliverBkPresentationMailData(id: string, data: any) {
    const response = await apiClient.patch(`${resource}/${id}/send_bk_presentation`, data)
    return response.data
  }
}
