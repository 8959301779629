import { Dispatch } from "vuex";
import RepositoryFactory from '@/api/repositoryFactory'
import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"

const createModule = (moduleName: string) => {

  const Repository = RepositoryFactory.get(moduleName)

  const MODULE = moduleName
  const SET_PAGINATION = 'set_pagination'
  const SET_DATA = 'set_data'
  const SET_RESPONSE = 'set_response'
  const SET_ELEMENT = 'set_element'
  const SET_ELEMENT_RESPONSE = 'set_element_response'
  const FETCH = 'load'
  const FETCH2 = 'load2'
  const GET = 'get'
  const EDIT = 'edit'
  const CREATE = 'create'
  const DELETE = 'delete'

  const state = {
    response: undefined,
    index: {},
    data: [],
    elementResponse: undefined,
    element: undefined,
    pagination: {
      maxPage: 1,
      pageNumber: 1,
      pageSize: 1,
      totalItems: 0
    }
  }

  const getters = {
  }

  const actions = {
    async [FETCH]({ commit }: any, queryParams?: { [key:string]: any }) {
      const data = await Repository.list(queryParams)
      if (queryParams?.include) {
        apiDataMorpher(data, queryParams.include)
      }
      commit(SET_RESPONSE, data)
      commit(SET_DATA, data.data)
      commit(SET_PAGINATION, {
        maxPage: data.meta?.maxPage ?? 1,
        pageNumber: data.meta?.pageNumber ?? 1,
        pageSize: data.meta?.pageSize ?? 1,
        totalItems: data.meta?.totalItems ?? 0
      })
      return data
    },
    async [FETCH2]({ commit }: any, queryParams?: { [key:string]: any }) {
      const data = await Repository.list(queryParams)
      commit(SET_RESPONSE, data)
      commit(SET_DATA, data.data)
      commit(SET_PAGINATION, {
        maxPage: data.meta?.maxPage ?? 1,
        pageNumber: data.meta?.pageNumber ?? 1,
        pageSize: data.meta?.pageSize ?? 1,
        totalItems: data.meta?.totalItems ?? 0
      })
      return data
    },
    async [GET]({ commit }: any, payload: { id: string, queryParams?: { [key:string]: any } }) {
      const queryParams = payload.queryParams ?? {}
      queryParams['filter[id]'] = payload.id
      const responseData = await Repository.list(queryParams)
      if (queryParams?.include) {
        apiDataMorpher(responseData, queryParams.include)
      }
      commit(SET_ELEMENT_RESPONSE, responseData)
      commit(SET_ELEMENT, responseData.data[0])
      return responseData.data[0]
    },
    [EDIT]({ commit }: any, payload: { id: string, body: { [key:string]: any } }) {
      return Repository.edit(payload.id, payload.body)
    },
    async [CREATE]({ commit }: any, payload: { [key:string]: any }) {
      const response = await Repository.create(payload)
      return response
    },
    async [DELETE]({ commit }: any, id: string) {
      await Repository.delete(id)
    },
  }

  const mutations = {
    [SET_PAGINATION](state: any, pagination: any) {
      state.pagination = pagination
    },
    [SET_RESPONSE](state: any, response: any) {
      state.response = response
    },
    [SET_DATA](state: any, data: any) {
      // state.index = data.reduce(( element, retVal ) => { retVal[element.id] = element; return retVal }, {})
      state.data = data
    },
    [SET_ELEMENT](state: any, element: any) {
      state.element = element
    },
    [SET_ELEMENT_RESPONSE](state: any, elementResponse: any) {
      state.elementResponse = elementResponse
    },
  }

  return {
    Repository,
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  }
}

export default {
  createModule
}
