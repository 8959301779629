import apiClient from './apiClient'
import { Log } from "@/models/logs"

export default {
  async update(parentType: string, parentId: string, logs: Log[]) {
    const repository = await import(`@/api/${parentType}Repository`)
    return await repository.default.edit(parentId, { attributes: { logs: logs } })
  },
  async delete(parentType: string, parentId: string, logId: string) {
    const response = await apiClient.delete(`${parentType}/${parentId}/log_destroy/${logId}`)
    return response.data
  }
}
