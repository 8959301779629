import apiClient from './apiClient'
import { AvatarUrl, User, UserData } from "@/models/users";
import { CollectionResourceDoc, SingleResourceDoc } from '@/models/jsonapi'

const resource = "/proto_users"

export default {
  async list(queryParams?: { [key:string]: any  }) {
    const response = await apiClient.get<CollectionResourceDoc<string, User>>(resource, { params: queryParams })
    return response.data
  },
  async get(id: string) {
    return apiClient.get<SingleResourceDoc<string, User>>(`${resource}/${id}`)
  },
  getCurrentUser: async () => {
    return apiClient.get<SingleResourceDoc<string, UserData>>(`${resource}/me`)
  },
  getSeller: async (resourceIdentifier) => {
    return apiClient.get<SingleResourceDoc<string, UserData>>(`${resource}/get_seller`, {params: resourceIdentifier})
  },
  updateUserSettings: async (id: string, settings: any) => {
    return apiClient.patch<SingleResourceDoc<string, UserData>>(`${resource}/${id}`, { attributes: { settings: settings } })
  }
}
