
  import TitleActions from "@/components/shared/TitleActions.vue";
  import Tile from "@/components/Tile.vue"
  import { computed, watch, defineComponent, ref, provide, InjectionKey, getCurrentInstance } from "vue"
  import { Tile as TileModel } from "@/models/dashboard"
  import FormStepper from "@/components/shared/FormStepper.vue"
  import { getKeycloak } from "@/plugins/keycloak";
  import { provideRefreshData } from "@/view_scripts/dashboardUtils";

  export const DashboardRefreshDataKey: InjectionKey<Function> = Symbol()

  export default defineComponent({
    name: "dashboard",
    components: {
      TitleActions,
      FormStepper,
      Tile
    },
    // channels: {
    //   ChatChannel: {
    //     connected() {},
    //     rejected() {},
    //     received(data) {},
    //     disconnected() {}
    //   }
    // },
    // mounted() {
    //   this.$cable.subscribe(
    //     {
    //       channel: 'ChatChannel',
    //       room: 'public'
    //     }
    //   )
    // },
    setup(_) {
      const root = getCurrentInstance()?.proxy
      const keycloakRef = getKeycloak()

      const dbData = computed<TileModel[]>(() => {
        const exclusionActionTypeIds = [
          's_cs_rate',
          's_h_noadd',
          'p_r_reserved',
        ]
        return root?.$store.state.dashboard.data.filter((tile: TileModel) => !exclusionActionTypeIds.includes(tile.sysId))
      })

      const role = computed(() => keycloakRef.value.tokenParsed?.resource_access?.['pflegehilfe-senioren-platform']['roles'][0])
      const notSellerRole = computed(() => role.value !== 'seller')

      const refreshData = () => notSellerRole.value ? root?.$store.dispatch('dashboard/load') : () => {}

      provideRefreshData(refreshData)

      refreshData()


      const displayRole= (role: string) => {
        const assigner = {
          internal: 'Innendienst',
          seller: 'Vertrieb',
          provider: 'Agentur',
          assistant: 'Assistent',
          admin: 'Administration'
        }
        return assigner[role]
      }
      const headerTitle = computed(() => `${displayRole(role.value ?? '')} Dashboard`)

      const userDashboard = computed(() => {
          return () => import(`@/views/${role.value}/Dashboard.vue`)
      })

      const titleActions = ref([
        {
          title: 'Marketing',
          icon: 'user-secret',
          actions: [
            {
              title: 'Link1',
              icon: 'address-book',
              onClick: () => alert('boubou')
            },
            {
              title: 'Link2',
              icon: 'address-book',
              onClick: () => alert('baba')
            }
          ]
        },
        {
          title: 'Vertrieb',
          icon: 'address-book',
          actions: [
            {
              title: 'Link3',
              icon: 'address-book',
              onClick: () => alert('bibi')
            }
          ]
        },
      ])

      watch(() => role, (userRole) => {
        if (userRole.value === 'seller') {
          window.location.replace(process.env.VUE_APP_NEW)
        }
      }, { immediate: true })

      return {
        dbData,
        headerTitle,
        notSellerRole,
        titleActions,
        userDashboard,
        refreshData,
      }
    }
  })
