import Vue from "vue"
import VueRouter from "vue-router";
import Hub from "@/views/Hub.vue"
import Dashboard from "@/views/Dashboard.vue"
import Suggestions from "@/views/Suggestions.vue"

Vue.use(VueRouter)

const routes: any[] = [
  {
    path: "/agencies",
    name: "agencies",
    component: Hub,
    props: { viewName: 'Agencies' }
  },
  {
    path: "/agencies/:id",
    name: "agency_show",
    component: Hub,
    props: route => ({ id: route.params.id, viewName: 'Agency' })
  },
  {
    path: "/",
    name: "dashboard",
    component: Dashboard
  },
  {
    path: "/leads",
    name: "leads",
    component: Hub,
    props: { viewName: 'Leads' }
  },
  {
    path: "/leads/new",
    name: "new_lead",
    component: Hub,
    props: { viewName: 'NewLead' }
  },
  {
    path: "/leads/:id",
    name: "lead_show",
    component: Hub,
    props: route => ({ id: route.params.id, viewName: 'Lead' })
  },
  {
    path: "/tickets/:id",
    name: "ticket_show",
    component: Hub,
    props: route => ({ id: route.params.id, viewName: 'Ticket' })
  },
  {
    path: "/tickets",
    name: "tickets",
    component: Hub,
    props: { viewName: 'Tickets' }
  },
  {
    path: "/contracts/:id",
    name: "contract_show",
    component: Hub,
    props: route => ({ id: route.params.id, viewName: 'Contract' })
  },
  {
    path: "/contracts",
    name: "contracts",
    component: Hub,
    props: { viewName: 'Contracts' }
  },
  {
    path: "/care_receiver_search",
    name: "care_receiver_search",
    component: Hub,
    props: { viewName: 'PostingSearch' }
  },
  {
    path: "/households/:id",
    name: "household_show",
    component: Hub,
    props: route => ({ id: route.params.id, viewName: 'Household' })
  },
  {
    path: "/households",
    name: "households",
    component: Hub,
    props: { viewName: 'Households' }
  },
  {
    path: "/postings",
    name: "postings",
    component: Hub,
    props: { viewName: 'Postings' }
  },
  {
    path: "/postings/:id",
    name: "posting_show",
    component: Hub,
    props: route => ({ id: route.params.id, viewName: 'Posting' })
  },
  {
    path: "/leadsources",
    name: "leadsources",
    component: Hub,
    props: { viewName: 'Leadsources' }
  },
  {
    path: "/users/:id",
    name: "user_show",
    component: Hub,
    props: route => ({ id: route.params.id, viewName: 'User' })
  },
  {
    path: "/users",
    name: "users",
    component: Hub,
    props: { viewName: 'Users' }
  },
  {
    path: "/profile",
    beforeEnter: (to, from) => {
      const url = `${process.env.VUE_APP_NEW}/profile`
      window.open(url, '_blank')?.focus()
      return false
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: Hub,
    props: { viewName: 'Settings' }
  },
  {
    path: "/care_givers",
    name: "care_givers",
    component: Hub,
    props: { viewName: 'CareGivers' }
  },
  {
    path: "/care_giver_instances/:id",
    name: "care_giver_instace_show",
    component: Hub,
    props: route => ({ id: route.params.id, viewName: 'CareGiverInstance' })
  },
  {
    path: "/care_receivers",
    name: "care_receivers",
    component: Hub,
    props: { viewName: 'CareReceivers' }
  },
  {
    path: "/care_receivers/:id",
    name: "care_receivers_show",
    component: Hub,
    props: route => ({ id: route.params.id, viewName: 'CareReceiver' })
  },
  {
    path: "/tasks",
    name: "tasks",
    component: Hub,
    props: { viewName: 'Tasks' }
  },
  {
    path: "/visors",
    name: "visors",
    component: Hub,
    props: { viewName: 'Visors' }
  },
  {
    path: "/visors/:id",
    name: "visor_show",
    component: Hub,
    props: route => ({ id: route.params.id, viewName: 'Visor' })
  },
  {
    path: "/care_stays",
    name: "care_stays",
    component: Hub,
    props: { viewName: 'CareStays' }
  },

  {
    path: "/suggestions",
    name: "suggestions",
    component: Hub,
    props: { viewName: 'Suggestions' }
  },
  {
    path: "/suggestions/:id",
    name: "suggestion",
    component: Hub,
    props: route => ({ id: route.params.id, viewName: 'Suggestion' })
  },
  {
    path: "/agency_bills",
    name: "agency_bills",
    component: Hub,
    props: { viewName: 'AgencyBills' }
  },
  // {
  //   path: "/seller_bills",
  //   name: "seller_bills",
  //   component: Hub,
  //   props: { viewName: 'SellerBills' }
  // },
  {
    path: "/v2/seller_bills",
    beforeEnter: (to, from) => {
      const url = `${process.env.VUE_APP_NEW}/seller_bills`
      window.open(url, '_blank')?.focus()
      // reject the navigation
      return false
    },
  },
  {
    path: "/seller_bills_old",
    name: "seller_bills_old",
    component: Hub,
    props: { viewName: 'SellerBills_old' }
  },
  {
    path: "/v2/agency_bills",
    beforeEnter: (to, from) => {
      const url = `${process.env.VUE_APP_NEW}/agency_bills`
      window.open(url, '_blank')?.focus()
      // reject the navigation
      return false
    },
  },
  {
    path: "/v2/postings",
    beforeEnter: (to, from) => {
      const url = `${process.env.VUE_APP_NEW}/postings`
      window.open(url, '_blank')?.focus()
      // reject the navigation
      return false
    },
  },
  {
    path: "/v2/reservations",
    beforeEnter: (to, from) => {
      const url = `${process.env.VUE_APP_NEW}/reservations`
      window.open(url, '_blank')?.focus()
      // reject the navigation
      return false
    },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
