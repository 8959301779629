import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('care_receivers')

export const GET_PAGES = 'get_pages'
export const SET_PAGES = 'set_pages'
export const LISTV2 = 'loadv2'

const extendedState = {
  ...state,
  pages: undefined
}

const extendedActions = {
  ...actions,
  [LISTV2]({ commit }: any, queryParams?: { [key: string]: any }) {
    return Repository.listV2(queryParams)
  },
  async [GET_PAGES]({ commit }: any) {
    const responseData = await Repository.pages()
    commit(SET_PAGES, responseData.crPages)
  }
}

const extendedMutations = {
  ...mutations,
  [SET_PAGES](state: any, pages: any) {
    state.pages = pages
  }
}

export default {
  namespaced,
  state: extendedState,
  getters,
  actions: extendedActions,
  mutations: extendedMutations,
}
