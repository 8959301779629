import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('postings')

export const DELIVER_BK_PRESENTATION_MAIL_DATA = 'deliver_bk_presentation_mail_data'
export const SET_BK_PRESENTATION_MAIL_DATA = 'set_bk_presentation_mail_data'
export const LISTV2 = 'loadv2'

const extendedState = {
  ...state,
  bkPresentationMailData: undefined
}

const extendedActions = {
  ...actions,
  [LISTV2]({ commit }: any, queryParams?: { [key: string]: any }) {
    return Repository.listV2(queryParams)
  },
  async [DELIVER_BK_PRESENTATION_MAIL_DATA]({ commit }: any, payload: { id: string, data: any }) {
    const responseData = await Repository.deliverBkPresentationMailData(payload.id, payload.data)
    commit(SET_BK_PRESENTATION_MAIL_DATA, responseData.data)
  }
}

const extendedMutations = {
  ...mutations,
  [SET_BK_PRESENTATION_MAIL_DATA](state: any, bkPresentationMailData: any) {
    state.bkPresentationMailData = bkPresentationMailData
  }
}

export default {
  namespaced,
  state: extendedState,
  getters,
  actions: extendedActions,
  mutations: extendedMutations,
}
