import apiClient from './apiClient'
import { CollectionResourceDoc, SingleResourceDoc } from '@/models/jsonapi'
import { Address } from "@/models/addresses"

const resource = "/addresses"

export default {
  async list(queryParams?: { [key: string]: any }) {
    const response = await apiClient.get<CollectionResourceDoc<string, Address>>(resource, { params: queryParams })
    return response.data
  },
  listV2(queryParams?: { [key: string]: any }) {
    const baseDomain = process.env.VUE_APP_API
    if (baseDomain) {
      apiClient.defaults.baseURL = baseDomain.replace('v1', 'v2')
      const response = apiClient.get<CollectionResourceDoc<string, Address>>(resource, { params: queryParams })
      apiClient.defaults.baseURL = baseDomain.replace('v2', 'v1')
      return response
    }
  },
  async get(id: string, queryParams?: { [key: string]: any }) {
    let params = {
      'filter[id]': id
    }
    if (queryParams) {
      params = {
        ...params,
        ...queryParams
      }
    }
    const response = await apiClient.get<CollectionResourceDoc<string, Address>>(resource, { params: params })
    return response.data
  },
  async create(data?: any) {
    const response = await apiClient.post(resource, data)
    return response.data
  },
  async edit(id: string, data?: any) {
    const response = await apiClient.patch(`${resource}/${id}`, data)
    return response.data
  },
  async delete(id: string) {
    const response = await apiClient.delete(`${resource}/${id}`)
    return response.data
  },
  async search(phrase: string, queryParams?: { [key: string]: any }) {
    let params = {
      'filter[phrase]': phrase
    }
    if (queryParams) {
      params = {
        ...params,
        ...queryParams
      }
    }
    const response = await apiClient.get<CollectionResourceDoc<string, Address>>(`${resource}/global_search`, { params: params })
    return response.data
  }
}
