import apiClient from './apiClient'
import { CollectionResourceDoc, SingleResourceDoc } from '@/models/jsonapi'
import { Household } from "@/models/households"

const resource = "/households"

export default {
  async list(queryParams?: { [key:string]: any  }) {
    const response = await apiClient.get<CollectionResourceDoc<string, Household>>(resource, { params: queryParams })
    return response.data
  },
  async get(id: string, queryParams?: { [key:string]: any  }) {
    let params = {
      'filter[id]': id
    }
    if (queryParams) {
      params = {
        ...params,
        ...queryParams
      }
    }
    const response = await apiClient.get<CollectionResourceDoc<string, Household>>(resource, { params: params })
    return response.data
  },
  async edit(id: string, data?: any) {
    const response = await apiClient.patch(`${resource}/${id}`, data)
    return response.data
  },
  async getNewPostingData(id: string) {
    const response = await apiClient.get(`${resource}/${id}/posting_new`)
    return response.data
  },
  // async createNewPosting(id: string, data?: any) {
  //   const response = await apiClient.post(`${resource}/${id}/posting_create`, data)
  //   return response.data
  // }
}
