
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import { NavbarAction } from "@/models/navbar_actions"

  interface Props {
    id: string,
    action: NavbarAction,
    childIconFirst: boolean
  }

  export default defineComponent({
    name: 'NestedDropdown',
    props: {
      id: {
        type: String,
        required: true
      },
      action: {
        type: Object,
        default: () => ({})
      },
      childIconFirst: {
        type: Boolean,
        default: true
      },
      noCaret: {
        type: Boolean,
        default: false
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance()?.proxy
      let isNestedDropdownVisible = false

      root.$root.$on('bv::dropdown::show', bvEvent => {
        if(bvEvent.componentId.replace(props.id, '') !== '') {
          isNestedDropdownVisible = true;
        }
      })

      root.$root.$on('bv::dropdown::hide', bvEvent => {
        if(bvEvent.componentId.replace(props.id, '') !== '') {
          isNestedDropdownVisible = false;
        }
        if(isNestedDropdownVisible) {
          bvEvent.preventDefault()
        }
      })

      return {}
    }
  })
