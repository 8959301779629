import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('suggestions')

export const GET_PRESENTATION_MAIL_DATA = 'get_presentation_mail_data'
export const SET_PRESENTATION_MAIL_DATA = 'set_presentation_mail_data'

const extendedState = {
  ...state,
  presentationMailData: undefined
}

const extendedActions = {
  ...actions,
  async [GET_PRESENTATION_MAIL_DATA]({ commit }: any, suggestionIds: string[]) {
    const responseData = await Repository.presentationMailData(suggestionIds)
    commit(SET_PRESENTATION_MAIL_DATA, responseData.data)
  }
}
  
const extendedMutations = {
  ...mutations,
  [SET_PRESENTATION_MAIL_DATA](state: any, presentationMailData: any) {
    state.presentationMailData = presentationMailData
  }
}

export default {
  namespaced,
  state: extendedState,
  getters,
  actions: extendedActions,
  mutations: extendedMutations,
}
