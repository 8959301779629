import apiClient from './apiClient'
import { Avi } from '@/models/avis'
import { CollectionResourceDoc, SingleResourceDoc } from '@/models/jsonapi'

const resource = "/seller_bills"

export default {
  async list(queryParams?: { [key:string]: any  }) {
    const response = await apiClient.get(resource, { params: queryParams })
    return response.data
  },
  seller_annual_bill_data(queryParams: { sellerId: string, year: number  }) {
    return apiClient.get(`${resource}/seller_annual_bill_data`, { params: queryParams })
  },
  pdf_data(queryParams: { 'filter[id]': string }) {
    return apiClient.get(`${resource}/pdf_data`, { params: queryParams })
  },
  edit(id: string, data?: any) {
    return apiClient.patch(`${resource}/${id}`, data)
  },
  entry_create(data: {id: string, billEntry: {[key: string]: any}}) {
    return apiClient.post(`${resource}/${data.id}/bill_entries`, {bill_entry: data.billEntry})
  },
  entry_update(data: {id: string, billEntry: {[key: string]: any}}) {
    return apiClient.patch(`${resource}/${data.id}/bill_entries/${data.billEntry.id}`, {bill_entry: data.billEntry})
  },
  entry_destroy(data: {id: string, billEntry: {[key: string]: any}}) {
    return apiClient.delete(`${resource}/${data.id}/bill_entries/${data.billEntry.id}`)
  }
}
