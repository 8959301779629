
  import { defineComponent } from "vue"
  import { Tile as TileModel } from "@/models/dashboard";

  interface Props {
    tileData: TileModel
  }

  export default defineComponent({
    name: "Tile",
    props: {
      tileData: {
        type: Object,
        required: true,
      }
      // title: {
      //   type: String,
      //   required: true
      // },
      // prioHighCount: {
      //   type: Number,
      //   required: true
      // },
      // prioLowCount: {
      //   type: Number,
      //   required: true
      // },
      // icon: {
      //   type: String,
      //   required: true
      // }
    }
  })
