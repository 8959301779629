import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('tickets')

export const GET_TICKET_MAIL_DATA = 'get_ticket_mail_data'
export const SET_TICKET_MAIL_DATA = 'set_ticket_mail_data'
export const DELIVER_TICKET_MAIL_DATA = 'deliver_ticket_mail_data'

const extendedState = {
  ...state,
  ticketMailData: null
}

const extendedActions = {
  ...actions,
  async [GET_TICKET_MAIL_DATA]({ commit }: any, id: string) {
    const responseData = await Repository.getTicketMailData(id)
    commit(SET_TICKET_MAIL_DATA, responseData.data)
  },
  async [DELIVER_TICKET_MAIL_DATA]({ commit }: any, payload: { id: string, data: any }) {
    const responseData = await Repository.deliverTicketMailData(payload.id, payload.data)
    return responseData
    // commit(SET_BK_PRESENTATION_MAIL_DATA, responseData.data)
  }
}

const extendedMutations = {
  ...mutations,
  [SET_TICKET_MAIL_DATA](state: any, ticketMailData: any) {
    state.ticketMailData = ticketMailData
  }
}

export default {
  namespaced,
  state: extendedState,
  getters,
  actions: extendedActions,
  mutations: extendedMutations,
}
