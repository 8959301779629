
// @ is an alias to /src
import { defineComponent, ref, computed, watch } from "vue";
import { provideKeycloak, getKeycloak } from "@/plugins/keycloak";
import Sidebar from "@/components/Sidebar.vue"
import { provideConfirmationModal, getConfirmationModal } from "@/plugins/confirmation"
import { provideAlertDialog, getAlertDialog } from "@/plugins/alert"
import { provideForm } from "@/components/shared/form/form-provider"

export default defineComponent({
  components: {
    Sidebar
  },
  setup() {
    document.title="PfS"
    const keycloak = provideKeycloak()
    const confirmationModal = provideConfirmationModal()
    const formSchema = provideForm()

    // const role = computed(() => keycloak.value.tokenParsed?.resource_access?.['pflegehilfe-senioren-platform']['roles'][0])

    // watch(role, (currentRole) => {
    //   if (currentRole === 'seller') {
    //     window.location.href = 'https://staging.crm.pflegehilfe-senioren.de/v2/dashboard'
    //   }
    // }, { immediate: true })

    const { show, message, variant } = provideAlertDialog()
    //const { show, message, variant } = getAlertDialog()
    const alertIcon = computed(() => variant.value === 'success' ? ['fas', 'check-circle'] : ['fas', 'times-circle'])

    const title = computed<string>(() => variant.value === 'success' ? 'Erfolg' : 'Fehler')

    // Set the theme from the saved settings
    const theme = localStorage.getItem('theme')
    if (theme) {
      document.documentElement.className = theme
    } else {
      localStorage.setItem('theme', 'dark')
      document.documentElement.className = 'dark'
    }

    return {
      keycloak,
      confirmationModal,
      formSchema,
      show,
      message,
      alertIcon,
      variant,
      title
    }
  }
})
