
  import { defineComponent, ref, watch, computed, onMounted, getCurrentInstance } from "vue";
  import moment from 'moment'

  export default defineComponent({
    name: "TaskEditModal",
    components: {
      SchemaForm: () => import("@/components/shared/form/SchemaForm.vue")
    },
    props: {
      visible: {
        type: Boolean,
        default: true
      },
      editMode: {
        type: Boolean,
        default: true
      },
      task: {
        type: Object,
        default: () => ({})
      },
      initialNote: {
        type: String
      },
      initialDueAt: {
        type: String
      },
      // initialVisibleAt: {
      //   type: String
      // },
      initialPriority: {
        type: Boolean
      },
      initialFrozen: {
        type: Boolean
      }
    },
    setup(props: any, { emit }) {
      const root = getCurrentInstance().proxy
      const title = computed(() => props.editMode ? 'Task bearbeiten' : 'Task erstellen')
      const modalVisible = ref(props.visible)
      const reminderForm = ref()
      watch(() => props.visible, (newValue) => {
        if (newValue){
          reminderForm.value = { attributes: {
            ...props.task.attributes,
            dueAt: props.initialDueAt,
            // visibleAt: props.initialVisibleAt,
            note: props.initialNote,
            priority: props.initialPriority || false,
            frozen: props.initialFrozen || false,
          }}
        }
        modalVisible.value = newValue
      }, { immediate: true })

      const reminderSchema = {
        $schema: "https://json-schema.org/draft/2019-09/schema",
        $id: "https://crm.pflegehilfe-senioren.de/reminder-model.json",
        type: "object",
        properties: {
          attributes: {
            type: "object",
            title: "Attributes",
            properties: {
              dueAt: { type: "string", title: "Wann", format: "date-time" },
              // visibleAt: { type: "string", title: "Sehbar", format: "date-time" },
              note: { type: "string", title: "Note" },
              priority: { type: "boolean", title: "Priorität" },
              frozen: { type: "boolean", title: "Geschützt" }
            }
          }
        }
      }

      const cancelableDatetime = () => import('@/components/shared/CancelableDateTime.vue')

      const uiSchema = computed(() => {
        return {
          $schema: "https://json-schema.org/draft/2019-09/schema",
          $id: "https://crm.pflegehilfe-senioren.de/reminder-view.json",
          $vocabulary: {
            "https://json-schema.org/draft/2019-09/vocab/core": true,
            "https://crm.pflegehilfe-senioren.de/ui-vocab": true
          },
          "ui:type": "form",
          "ui:widget": "b-row",
          type: "object",
          properties: {
            attributes: {
              type: "object",
              $ref: "https://crm.pflegehilfe-senioren.de/reminder-model.json#/properties/attributes",
              "ui:type": "group",
              "ui:label": "",
              properties: {
                dueAt: {
                  "ui:label": "Termin",
                  "ui:widget": cancelableDatetime,
                  "ui:options": {
                    type: "datetime",
                    'minute-step': 5,
                    cancelable: true
                  },
                  "ui:classNames": ["col-sm-6"],
                  $ref: "https://crm.pflegehilfe-senioren.de/reminder-model.json#/properties/attributes/properties/dueAt"
                },
                // visibleAt: {
                //   "ui:label": "Wiedervorlage",
                //   "ui:widget": cancelableDatetime,
                //   "ui:options": {
                //     type: "datetime",
                //     'minute-step': 5,
                //     cancelable: true
                //   },
                //   "ui:classNames": ["col-sm-6"],
                //   $ref: "https://crm.pflegehilfe-senioren.de/reminder-model.json#/properties/attributes/properties/visibleAt"
                // },
                note: {
                  "ui:label": "Notiz",
                  "ui:widget": "b-textarea",
                  $ref: "https://crm.pflegehilfe-senioren.de/reminder-model.json#/properties/attributes/properties/note"
                },
                priority: {
                  "ui:type": "checkbox",
                  "ui:label": "Priorität",
                  "ui:classNames": ["col-6"],
                  $ref: "https://crm.pflegehilfe-senioren.de/reminder-model.json#/properties/attributes/properties/priority",
                },
                frozen: {
                  "ui:type": "checkbox",
                  "ui:label": "Geschützt",
                  "ui:options": {description: "Das System wird diesen Task nicht löschen."},
                  "ui:classNames": ["col-6"],
                  $ref: "https://crm.pflegehilfe-senioren.de/reminder-model.json#/properties/attributes/properties/frozen",
                },
              }
            }
          }
        }
      })

      const lastDueAt = ref(props.initialDate)
      const updatedForm = (formValue) => {
        if (formValue.attributes.dueAt && moment(lastDueAt.value).format() !== moment(formValue.attributes.dueAt).format()) {
          lastDueAt.value = formValue.attributes.dueAt
          // formValue.attributes.visibleAt = moment(formValue.attributes.dueAt).subtract(1, 'days').format()
        }
        reminderForm.value = formValue
      }

      const modalResolveAction = async () => {
        if (props.editMode) {
          await root.$store.dispatch('tasks/edit', { id: props.task.id, body: reminderForm.value })
          emit('done', true)
        } else {
          await root.$store.dispatch('tasks/create', reminderForm.value)
          emit('done', true)
        }
      }

      return {
        modalVisible,
        modalResolveAction,
        reminderSchema,
        reminderForm,
        uiSchema,
        updatedForm,
        title
      }
    }
  })
