
  import { defineComponent } from "vue"

  interface Props { show: boolean }

  export default defineComponent({
    name: 'LoadingOverlay',
    props: {
      show: {
        type: Boolean,
        required: true
      },
    },
    // setup(props: Props) {

    //   return {}
    // }
  })
