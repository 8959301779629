export interface Query {
  logicalOperator: string,
  children: (GroupQuery|RuleQuery)[]
}

export interface GroupQuery {
  type: 'query-builder-group' 
  query: Query
}

export interface RuleQuery {
  type: 'query-builder-rule'
  query: {
    rule?: string,
    operator?: string,
    operand?: string,
    value?: any
  }
}

export function instanceOfGroupQuery(obj: any): obj is GroupQuery {
  return obj.type === 'query-builder-group'
}

export function instanceOfRuleQuery(obj: any): obj is RuleQuery {
  return obj.type === 'query-builder-rule'
}

export interface Rule {
  id: string,
  type: string,
  subtype?: string,  
  label: string,
  operators: string[],
  operands: string[],
  choices?: any[]
}

export const defaultRuleTypes = {
  text: {
    operators: [ 'is', 'is not', 'contains', 'does not contain', 'is present', 'is blank', 'starts with', 'ends with' ],
  },
  boolean: {
    operators: []
  },
  number: {
    operators: [ 'equals', 'does not equal', 'greater than', 'greater than or equal', 'less than', 'less than or equal', 'is present', 'is blank' ],
  },
  date: {
    operators: [ 'is', 'is not', 'is before', 'is after', 'is present', 'is blank' ]
  },
  datetime: {
    operators: [ 'is', 'is not', 'is before', 'is after', 'is present', 'is blank' ]
  },
  time: {
    operators: [ 'is', 'is not', 'is before', 'is after', 'is present', 'is blank' ]
  },
  select: {
    operators: [ 'is', 'is not', 'is present', 'is blank' ],
  }
}
 
