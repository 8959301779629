import apiClient from './apiClient'
import { CollectionResourceDoc, SingleResourceDoc } from '@/models/jsonapi'
import { Address } from "@/models/addresses"

const resource = "/bill_addresses"

export default {
  listV2(queryParams?: { [key: string]: any }) {
    const baseDomain = process.env.VUE_APP_API
    if (baseDomain) {
      apiClient.defaults.baseURL = baseDomain.replace('v1', 'v2')
      const response = apiClient.get<CollectionResourceDoc<string, Address>>(resource, { params: queryParams })
      apiClient.defaults.baseURL = baseDomain.replace('v2', 'v1')
      return response
    }
  },
}
