import apiClient from './apiClient'
import { CollectionResourceDoc, SingleResourceDoc } from '@/models/jsonapi'
import { AgencyBill } from '@/models/agency_bills'

const resource = "/agency_bills"

export default {
  async bill_data(queryParams: { year: number, month: number }) {
    const response = await apiClient.get(`${resource}/bill_data`, { params: queryParams })
    return response.data
  },
  async edit(id: string, data?: any) {
    return apiClient.patch(`${resource}/${id}`, data)
  },
  create(data?: any) {
    return apiClient.post(resource, data)
  },
  list(queryParams?: { [key:string]: any  }) {
    return apiClient.get<CollectionResourceDoc<string, AgencyBill>>(resource, { params: queryParams })
  },
  entry_create(data: {id: string, billEntry: {[key: string]: any}}) {
    return apiClient.post(`${resource}/${data.id}/bill_entries`, {bill_entry: data.billEntry})
  },
  entry_update(data: {id: string, billEntry: {[key: string]: any}}) {
    return apiClient.patch(`${resource}/${data.id}/bill_entries/${data.billEntry.id}`, {bill_entry: data.billEntry})
  },
  entry_destroy(data: {id: string, billEntry: {[key: string]: any}}) {
    return apiClient.delete(`${resource}/${data.id}/bill_entries/${data.billEntry.id}`)
  }
}
