import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('visors')

export const CREATE_SUGGESTION = 'create_suggestion'
export const SET_SUGGESTION = 'set_suggestion'
export const SEARCH = 'search'
export const SET_SEARCH_RESULT = 'set_search_result'
export const FEEDBACK_CREATE = 'feedback_create'
export const LISTV2 = 'loadv2'

const extendedState = {
  ...state,
  suggestion: undefined,
  searchResult: undefined
}


const extendedActions = {
  ...actions,
  [LISTV2]({ commit }: any, queryParams?: { [key:string]: any }) {
    return Repository.listV2(queryParams)
  },
  async [CREATE_SUGGESTION]({ commit }: any, payload: { id: string, suggestionData?: any }) {
    const responseData = await Repository.edit(payload.id, payload.suggestionData)
    commit(SET_SUGGESTION, responseData.data)
  },
  async [SEARCH]({ commit }: any, queryParams?: { [key:string]: any }) {
    const responseData = await Repository.search(queryParams)
    commit(SET_SEARCH_RESULT, responseData.data)
    return responseData
  },
  async [FEEDBACK_CREATE]({ commit }: any, payload: { id: string, data: any }) {
    console.log('store data: ', payload.data)
    return Repository.feedback_create(payload.id, payload.data)
    // commit(SET_SUGGESTION, responseData.data)
  }
}

const extendedMutations = {
  ...mutations,
  [SET_SUGGESTION](state: any, suggestion: any) {
    state.suggestion = suggestion
  },
  [SET_SEARCH_RESULT](state: any, searchResult: any) {
    state.searchResult = searchResult
  }
}

export default {
  namespaced,
  state: extendedState,
  getters,
  actions: extendedActions,
  mutations: extendedMutations,
}
