import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('leads')

export const LISTV2 = 'loadv2'


const extendedActions = {
  ...actions,
  [LISTV2]({ commit }: any, queryParams?: { [key:string]: any }) {
    return Repository.listV2(queryParams)
  }
}

export default {
  namespaced,
  state: state,
  getters,
  actions: extendedActions,
  mutations: mutations,
}
