
import { defineComponent, ref, computed } from 'vue'
import { useModelWrapper } from '@/utils/modelWrapper'
import { Query, Rule, GroupQuery, RuleQuery, defaultRuleTypes } from './querybuilder'
import QueryBuilderChild from './QueryBuilderChild.vue'

interface Props {
  query: Query,
  rules: Rule[],
  ruleTypes: any,  
  index: number,
  depth: number,
  maxDepth?: number
}

export default defineComponent({
  components: {
    QueryBuilderChild
  },
  model: {
    prop: 'query',
    event: 'update:query'
  },
  props: {
    ruleTypes: {
      type: Object,
      default: () => (defaultRuleTypes)
    },
    query: {
      type: Object,
      required: true
    },
    rules: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    },
    maxDepth: {
      type: Number
    },
    depth: {
      type: Number,
      default: 1
    }
  },
  setup(props: Props, { emit }) {
    const internalQuery = useModelWrapper(props, emit, 'query')

    const addRule = () => {
      let child: RuleQuery = {
        type: 'query-builder-rule',
        query: {}
      }

      internalQuery.value.children.push(child)
    }

    const addGroup = () => {
      if ( !props.maxDepth || props.depth < props.maxDepth ) {
        const children = [
          ...internalQuery.value.children, 
          {
            type: 'query-builder-group',
            query: {
              logicalOperator: 'AND',
              children: []
            }
          } as GroupQuery
        ]
        internalQuery.value.children = children
      }
    }

    const remove = () => {
      emit('child-deletion-requested', props.index)
    }

    return {
      internalQuery,
      addRule,
      addGroup,
      remove
    }
  }
})
