
  import draggable from "vuedraggable"
  import { computed, defineComponent, ref, watch } from "vue";
  import FormStepper from "../FormStepper.vue"
  import HeadCell from "@/components/shared/table/cells/HeadCell.vue"
  import { Column } from "@/models/table";
  import QueryBuilderGroup from '@/components/shared/table/querybuilder/QueryBuilderGroup.vue'
  import { filtersToQuery, queryToFilters } from '@/utils/queryMapper'

  interface StorageSettings {
    primarySort: string,
    secondarySort: string,
    primarySortDirection: number,
    secondarySortDirection: number,
    visibleColumns: string[],
  }

  interface Props {
    name: string,
    opts: object,
    columns: Column[],
    items: any[],
    getItems?: Function,
    totalItems: number,
    fullscreen: boolean,
    pageOptions: any[],
    initialFilters: any
  }

  export default defineComponent({
    props: {
      name: {
        type: String,
        require: true,
      },
      opts: {
        type: Object,
        default: function() {
          return {stacked: "md"}
        },
      },
      columns: {
        type: Array,
        required: true,
      },
      items: {
        type: Array,
        default: () => [],
      },
      getItems: {
        type: Function
      },
      totalItems: {
        type: Number,
        required: true
      },
      fullscreen: {
        type: Boolean,
        default: true,
      },
      pageOptions: {
        type: Array,
        default: () => [ 10, 25, 50 ]
      },
      sortBy: {
        type: String
      },
      sortDesc: {
        type: Boolean
      },
      filterRules: {
        type: Array
      },
      initialFilters: {
        type: Object
      }
    },
    components: {
      draggable,
      FormStepper,
      QueryBuilderGroup,
      HeadCell
    },
    setup(props: Props) {
      const table = ref()
      const fullScreenModal = ref()

      // Table settings
      const settingsStorageKey = computed(() => `${props.name}TableSettings`)
      const localStorageTableSettings = computed({
        get: () => {
          const s = localStorage.getItem(settingsStorageKey.value)
          return s ? JSON.parse(s) : null
        },
        set: (value: StorageSettings) => {
          localStorage.setItem(settingsStorageKey.value, JSON.stringify(value))
        }
      })
      const saveLocalStorageTableSettings = () => {
        localStorageTableSettings.value = {
          primarySort: primarySort,
          secondarySort: secondarySort,
          primarySortDirection: primarySortDirection,
          secondarySortDirection: secondarySortDirection,
          visibleColumns: visibleColumns.value,
        }
      }

      // Initialize data
      const s = localStorageTableSettings.value
      const primarySort = s?.primarySort ?? ""
      const secondarySort = s?.secondarySort ?? ""
      const primarySortDirection = s?.primarySortDirection ?? 0
      const secondarySortDirection = s?.secondarySortDirection ?? 0

      // Set visible columns
      const getVisibleColumns = () => localStorageTableSettings.value?.visibleColumns ?? props.columns.map(cn => cn.title)
      const visibleColumns = ref(getVisibleColumns())
      watch(() => props.columns, (newValue) => {
        visibleColumns.value = getVisibleColumns()
      })

      const removeColumn = (index: number) => {
        visibleColumns.value.splice(index, 1)
      }

      const shouldAddColumn = (to: any, fro: any, dragEl: { id: string }, event: any) => !visibleColumns.value.includes(dragEl.id)

      const cloneToVisibleColumn = (original: Column) => original.title

      const perPage = ref(props.pageOptions[0])
      const currentPage = ref(1)

      const fields = computed(() => {
        return props.columns
          .filter(column => visibleColumns.value.includes(column.title))
          .sort((firstColumn, secondColumn) => {
            return visibleColumns.value.indexOf(firstColumn.title) - visibleColumns.value.indexOf(secondColumn.title)
          })
          .map(column => {
            return { key: column.key, label: column.title, sortable: column.sortable, component: column.component, options: column.options }
          })
      })

      const isEdit = ref(false)


      // Querybuilder
      const query = ref(props.initialFilters ? filtersToQuery(props.initialFilters) : {
        logicalOperator: 'AND',
        children: []
      })

      const filter = ref(queryToFilters(query.value))

      const applyFilters = () => {
        filter.value = queryToFilters(query.value)
      }

      const refreshItems = () => {
        table.value.refresh()
      }

      return {
        table,
        fields,
        visibleColumns,
        removeColumn,
        shouldAddColumn,
        cloneToVisibleColumn,
        saveLocalStorageTableSettings,
        isEdit,
        perPage,
        currentPage,
        query,
        refreshItems,
        filter,
        applyFilters,
        fullScreenModal
      }
    }
  })
