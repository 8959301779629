import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('agency_bills')

export const BILL_DATA = 'bill_data'
export const ENTRY_CREATE = 'entry_create'
export const ENTRY_UPDATE = 'entry_update'
export const ENTRY_DESTROY = 'entry_destroy'

// const extendedState = {
//   ...state,
//   newPostingData: undefined
// }

const extendedActions = {
  ...actions,
  [BILL_DATA]({ commit }: any, data: {month: number, year: number}) {
    return Repository.bill_data(data)
    // commit(SET_NEW_POSTING_DATA, response.data)
  },
  async [ENTRY_CREATE]({ commit }: any, data: {id: string, billEntry: {[key: string]: any}}) {
    return Repository.entry_create(data)
    // commit(SET_NEW_POSTING_DATA, response.data)
  },
  async [ENTRY_UPDATE]({ commit }: any, data: {id: string, billEntry: {[key: string]: any}}) {
    return Repository.entry_update(data)
    // commit(SET_NEW_POSTING_DATA, response.data)
  },
  async [ENTRY_DESTROY]({ commit }: any, data: {id: string, billEntry: {[key: string]: any}}) {
    return Repository.entry_destroy(data)
    // commit(SET_NEW_POSTING_DATA, response.data)
  },
}

// const extendedMutations = {
//   ...mutations,
//   [SET_NEW_POSTING_DATA](state: any, data: any) {
//     state.newPostingData = data
//   }
// }

export default {
  namespaced,
  state,
  getters,
  actions: extendedActions,
  mutations,
}
