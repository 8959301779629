import { provide, inject, ref } from 'vue'
import TasksRepository from '@/api/tasksRepository'
import TaskEditModal from "@/components/shared/modals/TaskEditModal.vue"

export const DashboardRefreshDataKey = Symbol()

export function provideRefreshData(refreshData: () => void) {
  provide(DashboardRefreshDataKey, refreshData)
}

export function getRefreshData() {
  const injection = inject<() => void>(DashboardRefreshDataKey)
  if (!injection) throw new Error("No refreshData function provided")
  return injection
}

export const addAction = (
  columns: any,
  newAction: { title: string, icon: string[], action: Function }
) => {
  const actions = columns.find((column: any) => column.key === 'actions')

  if (!actions) {
    columns.push({
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "actions",
      label: "Aktionen",
      sortable: false,
      options: {
        buttons: [ newAction ]
      }
    })
  } else {
    actions.options.buttons.push(newAction)
  }

  return columns
}


export const initializeModal = (root: any, update: Function) => {
  const modalType = ref()
  const modalProps = ref<any>()

  const showTaskEditModal = async (data: any) => {
    const task = data.item
    modalType.value = TaskEditModal
    modalProps.value = {
      visible: true,
      task: task,
      initialNote: task.attributes.note ?? '',
      initialDueAt: task.attributes.dueAt ?? '',
      initialVisibleAt: task.attributes.visibleAt ?? '',
      initialPriority: task.attributes.priority ?? false,
      initialFrozen: task.attributes.frozen ?? false
    }
  }

  const addReminderAction = (columns: any) => {
    return addAction(columns, {
      title: 'Erinnerung erstellen',
      icon: ['fa', 'bell'],
      action: showTaskEditModal
    })
  }

  const addDestroyTaskAction = (columns: any) => {
    return addAction(columns, {
      title: 'Aufgabe erledigt',
      icon: ['fad', 'check-circle'],
      action: async (data: any) => {
        const confirmation = await root.$bvModal.msgBoxConfirm("Möchtest du diesen Task wirklich löschen?", {
          title: 'Bestätigung',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        if (confirmation) {
          await TasksRepository.delete(data.item.id)
          update()
        }
      }
    })
  }

  return {
    modalType,
    modalProps,
    addReminderAction,
    addDestroyTaskAction,
  }
}

export const taskTitleColumn = {
  component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  key: "attributes.action_type.attributes.title",
  label: "Titel",
  title: "Titel",
  sortable: false,
  // options: {
  //   contentFunction: (data: any) => {
  //     console.log({data})
  //     const dat = data.item.attributes.action_type.attributes
  //     return dat.title
  //   }
  // }
}

export const taskDueAtColumn = {
  component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  key: "attributes.dueAt",
  label: "Termin",
  title: "Termin",
  sortable: true,
  options: {type: 'datetime'}
}

export const taskNotesColumn = {
  component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  key: "attributes.note",
  label: "Notizen",
  title: "Notizen",
  sortable: false,
}

export const taskFrozenColumn = {
  component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  key: "attributes.frozen",
  label: "Geschützt",
  title: "Geschützt",
  sortable: false,
}

export const taskPriorityColumn = {
  component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  label: "Prio",
  title: "Prio",
  key: "attributes.priority",
}
export const defTaskColumns = [taskDueAtColumn, taskNotesColumn]

export const addDefaultTaskColumns = (columns: any) => {
  const idx = columns.findIndex((c) => c.key==='actions')
  if (idx === -1) {
    columns.push(...defTaskColumns)
  } else {
    columns.splice(idx, 0, ...defTaskColumns)
  }
  return columns
}



