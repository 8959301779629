// import Vue from 'vue'

import { Dispatch } from "vuex";

const state = {}

const getters = {
  getLocalStorageLogIn: () => {
    return JSON.parse(localStorage.getItem('logIn') ?? '{}')
  },
}

const actions = {
  updateLocalStorageLogIn: ({ getters }: { getters: any }, newLogIn: any) => {
    const presentLogIn = getters.getLocalStorageLogIn
    const merged = {...presentLogIn, ...newLogIn}
    localStorage.setItem('logIn', JSON.stringify(merged))
    return merged
  },
  clearLocalStorageLogIn: () => {
    localStorage.removeItem('logIn')
  },
  logout: ({ dispatch }: { dispatch: Dispatch }) => {
    dispatch('clearLocalStorageLogIn')
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
