import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('households')

export const GET_NEW_POSTING_DATA = 'get_new_posting_data'
export const SET_NEW_POSTING_DATA = 'set_new_posting_data'
export const CREATE_NEW_POSTING = 'create_new_posting'

const extendedState = {
  ...state,
  newPostingData: undefined
}

const extendedActions = {
  ...actions,
  // async [CREATE_NEW_POSTING]({ commit }: any, payload: { id: string, body?: any }) {
  //   Repository.createNewPosting(payload.id, payload.body)
  // },
  async [GET_NEW_POSTING_DATA]({ commit }: any, id: string) {
    const response = await Repository.getNewPostingData(id)
    commit(SET_NEW_POSTING_DATA, response.data)
  },
}

const extendedMutations = {
  ...mutations,
  [SET_NEW_POSTING_DATA](state: any, data: any) {
    state.newPostingData = data
  }
}

export default {
  namespaced,
  state: extendedState,
  getters,
  actions: extendedActions,
  mutations: extendedMutations,
}
