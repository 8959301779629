import ModuleFactory from '@/store/modules/moduleFactory'

const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('addresses')

export const SEARCH = 'search'
export const SET_SEARCH_RESULT = 'set_search_result'
export const LISTV2 = 'loadv2'

const extendedState = {
  ...state,
  searchResult: undefined
}

const extendedActions = {
  ...actions,
  [LISTV2]({ commit }: any, queryParams?: { [key: string]: any }) {
    return Repository.listV2(queryParams)
  },
  async [SEARCH]({ commit }: any, payload: { phrase: string, queryParams?: { [key: string]: any } }) {
    const responseData = await Repository.search(payload.phrase, payload.queryParams)
    commit(SET_SEARCH_RESULT, responseData)
  }
}

const extendedMutations = {
  ...mutations,
  [SET_SEARCH_RESULT](state: any, searchResult: any) {
    state.searchResult = searchResult
  }
}

export default {
  namespaced,
  state: extendedState,
  getters,
  actions: extendedActions,
  mutations: extendedMutations,
}
