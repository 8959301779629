
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import NestedDropdown from "@/components/shared/NestedDropdown.vue"
  import { NavbarAction } from "@/models/navbar_actions"

  interface Props {
    pretitle?: string,
    title?: string,
    actions: NavbarAction[]
  }

  export default defineComponent({
    name: 'ActionTitle',
    components: {
      NestedDropdown
    },
    props: {
      pretitle: {
        type: String
      },
      title: {
        type: String,
      },
      actions: {
        type: Array,
        default: () => []
      }
    },
    setup(props: Props) {
      let isDropdown2Visible = false

      const root = getCurrentInstance()!!.proxy
      root.$on('bv::dropdown::show', bvEvent => {
        if(bvEvent.componentId === 'level-1') {
          isDropdown2Visible = true;
        }
      })

      root.$on('bv::dropdown::hide', bvEvent => {
        if(bvEvent.componentId === 'level-1') {
          isDropdown2Visible = false;
        }
        if(isDropdown2Visible) {
          bvEvent.preventDefault()
        }
      })

      return {
      }
    }
  })
