import Vue from "vue"
import Vuex from "vuex"
import application_store from "./modules/application_store"
import agency_bills from '@/store/modules/agency_bills'
import addresses from '@/store/modules/adresses'
import agencies from '@/store/modules/agencies'
import bill_addresses from '@/store/modules/bill_addresses'
import care_givers from '@/store/modules/care_givers'
import care_giver_instances from '@/store/modules/care_giver_instances'
import care_receivers from '@/store/modules/care_receivers'
import care_stays from '@/store/modules/care_stays'
import contracts from '@/store/modules/contracts'
import households from '@/store/modules/households'
import file_meta_datas from '@/store/modules/file_meta_datas'
import leads from '@/store/modules/leads'
import leadsources from '@/store/modules/leadsources'
import lists from '@/store/modules/lists'
import messages from '@/store/modules/messages'
import postings from '@/store/modules/postings'
import proto_leadsources from '@/store/modules/proto_leadsources'
import seller_bills from '@/store/modules/seller_bills'
import suggestions from '@/store/modules/suggestions'
import segments from '@/store/modules/segments'
import subscriptions from '@/store/modules/subscriptions'
import tasks from '@/store/modules/tasks'
import tickets from '@/store/modules/tickets'
import users from '@/store/modules/users'
import visors from '@/store/modules/visors'
import dashboard from '@/store/modules/dashboard'
import reservations from '@/store/modules/reservations'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    application_store,
    addresses,
    agencies,
    agency_bills,
    bill_addresses,
    care_receivers,
    care_stays,
    care_givers,
    care_giver_instances,
    contracts,
    file_meta_datas,
    households,
    leads,
    leadsources,
    lists,
    messages,
    postings,
    proto_leadsources,
    segments,
    seller_bills,
    subscriptions,
    suggestions,
    tasks,
    tickets,
    users,
    visors,
    dashboard,
    reservations
  },
})
