import ModuleFactory from '@/store/modules/moduleFactory'

// export default ModuleFactory.createModule('segments')
const { Repository, namespaced, state, getters, actions, mutations } = ModuleFactory.createModule('segments')

export const LISTV2 = 'list_v2'

const extendedActions = {
  ...actions,
  async [LISTV2]({ commit }: any, queryParams?: { [key:string]: any }) {
    return await Repository.list_v2(queryParams)
  }
}

export default {
  namespaced,
  state,
  getters,
  actions: extendedActions,
  mutations,
}