import { render, staticRenderFns } from "./FAButton.vue?vue&type=template&id=4ef2be1d&scoped=true&lang=pug"
import script from "./FAButton.vue?vue&type=script&lang=ts"
export * from "./FAButton.vue?vue&type=script&lang=ts"
import style0 from "./FAButton.vue?vue&type=style&index=0&id=4ef2be1d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef2be1d",
  null
  
)

export default component.exports